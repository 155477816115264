import qs, { ParsedQs } from 'qs';
import React, { useContext, useState, useEffect } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as PlusSVG } from '../../../images/plus_circle_outline.svg';
import { MemberData, MembersListData } from '../../../types/auth';
import { isBrowser } from '../../../utils/browser';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from '../../../utils/keys';
import CustomToast from '../../global/customToast/customToast';
import Loading from '../../global/loading/loading';
import './members.scss';
import Modal from '../../global/modal/modal';
import { Paginations, LayoutType } from '../../global/pagination/pagination';
import Heading from '../general/heading/heading';
import MemberInviteModal from '../memberInviteModal/memberInviteModal';

const Members: React.FC = () => {
  const { entitiesList, i18n, pageDataLoading } = useContext(GlobalContext);

  const currentLocale: LocaleType = useLocaleFromRouter();
  const [modal, setModal] = useState<boolean | null>(null);
  const [alert, setAlert] = useState<boolean | null>(null);

  const membersEntitiesList = entitiesList as MembersListData;
  const [members, setMembers] = useState<MembersListData>(membersEntitiesList);
  const itemsPerPage = 8;
  const numPages = Math.ceil(members.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list, setList] = useState<Array<MemberData>>(
    members.slice(0, itemsPerPage * currentPage)
  );

  // Set to first page in case if list change (ex: form user selection)
  useEffect(() => {
    setMembers(membersEntitiesList);
    setCurrentPage(1);
    setList(membersEntitiesList.slice(0, itemsPerPage * 1));
  }, [membersEntitiesList]);

  useEffect(() => {
    setList(
      members.slice(
        (currentPage - 1) * itemsPerPage,
        itemsPerPage * currentPage
      )
    );
  }, [currentPage]);

  // Set page based off url params
  useEffect(() => {
    const locationSearchState: ParsedQs = qs.parse(location.search.slice(1));
    if (locationSearchState) {
      const qpage = Number(locationSearchState.page);
      let page = 1;
      if (!isNaN(qpage) && qpage > 1) {
        page = qpage;
        if (qpage > numPages) {
          page = numPages;
        }
      }

      setCurrentPage(page);
    }
  }, [location.search]);

  const setPage = (page: number) => {
    if (isBrowser) {
      history.pushState({ page }, '', `?page=${page}`);
    }

    setCurrentPage(page);
  };

  // Update members list and current page
  const updateList = (newMembersList: MembersListData) => {
    const members = newMembersList as MembersListData;
    setMembers(members);
    setList(
      members.slice(
        (currentPage - 1) * itemsPerPage,
        itemsPerPage * currentPage
      )
    );
  };

  const renderListItem = (member: MemberData): JSX.Element => {
    const { id, firstName, lastName, email, status } = member;
    return (
      <a key={`${status}_${id}`} className="table-content-row">
        <div className="mobile-top-section">
          <div
            className={`cell-content  status ${
              status === 'member' ? 'is-member' : 'is-invitation'
            }`}
          >
            {i18n?.t(`dashboard.members.status.${status}`)}
          </div>
        </div>

        <div className="title-cell name-col">
          <span className="cell-content">
            {' '}
            {firstName || lastName ? (
              <>
                {firstName}
                {firstName && lastName && ` `}
                {lastName}
              </>
            ) : (
              '-'
            )}
          </span>
        </div>

        <div className="table-cell email-col">
          <span className="cell-content">
            <span className="mobile-label">
              {i18n?.t('dashboard.members.email')}:
            </span>
            {email}
          </span>
        </div>

        <div className="table-cell status-col">
          <div
            className={`cell-content  status ${
              status === 'member' ? 'is-member' : 'is-invitation'
            }`}
          >
            {i18n?.t(`dashboard.members.status.${status}`)}
          </div>
        </div>
      </a>
    );
  };

  return (
    <>
      <Modal modal={modal} setModal={setModal}>
        <MemberInviteModal
          locale={currentLocale}
          setModal={setModal}
          setAlert={setAlert}
          updateList={updateList}
        />
      </Modal>
      <CustomToast toast={alert} setToast={setAlert}>
        <span className="bold">{i18n?.t('alertSub.allDone')}</span>
        <span>{i18n?.t('alert.invitation_sent')}</span>
      </CustomToast>
      <div className="dashboard-members-list-wrapper">
        <Heading label={i18n?.t('dashboard.members.header')} />

        <div className={`list-container `}>
          <div className="members-table-container">
            <div className="table-heading">
              <h3 className="table-title-cell name-col">
                {i18n?.t('dashboard.members.name')}
              </h3>
              <h3 className="email-col">
                {i18n?.t('dashboard.members.email')}
              </h3>
              <h3 className="status-col">
                {i18n?.t('dashboard.members.status')}
              </h3>
            </div>

            {!pageDataLoading &&
              (list.length ? (
                <>
                  {list.map((member) => {
                    if (!member) return null;

                    return renderListItem(member);
                  })}
                  <a
                    className="table-content-row create-link"
                    onClick={() => setModal(true)}
                  >
                    {i18n?.t('dashboard.members.create_invite')} <PlusSVG />
                  </a>
                </>
              ) : (
                <div className="table-content-row item-row empty">
                  {i18n?.t('dashboard.members.no_members')}
                </div>
              ))}

            {pageDataLoading && (
              <div className="table-content-row item-row loading-row">
                <Loading size="medium" />
              </div>
            )}
          </div>
        </div>

        {!pageDataLoading && (
          <div className="member-dashboard-pagination">
            <Paginations
              id={generateKey('member-dashboard-pagination')}
              limit={itemsPerPage}
              currentPage={currentPage}
              numPages={numPages}
              setPage={setPage}
              layout={LayoutType.square}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Members;
