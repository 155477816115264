import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

import * as styles from './customButton.scss';

export enum buttonOptions {
  filled = 'filled',
  outline = 'outline',
  filled_search = 'filled_search',
  filled_light = 'filled_light,',
}

export enum bsButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

type CustomButton = {
  style: buttonOptions;
  className?: string;
  target?: string;
  classSuffix?: string;
  ariaLabel?: string;
  onClick?: () => void;
  type?: bsButtonTypes;
  children: ReactNode;
  disabled?: boolean;
};

const CustomButton: React.FC<CustomButton> = ({
  children,
  style,
  className = '',
  classSuffix = '',
  ariaLabel = '',
  type = undefined,
  onClick,
  disabled = false,
}): JSX.Element => {
  const cx = classNames.bind(styles);
  const buttonStyle = cx({
    filled: style === buttonOptions.filled,
    outline: style === buttonOptions.outline,
    filled_search: style === buttonOptions.filled_search,
    filled_light: style === buttonOptions.filled_light,
  });
  return (
    <span className={`${buttonStyle}${classSuffix} ${className}`}>
      <Button
        type={type}
        aria-label={ariaLabel}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </span>
  );
};

export default CustomButton;
