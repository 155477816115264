import axios from 'axios';
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from 'react';

import GlobalContext from '../../../context/global-context';
import { MembersListData } from '../../../types/auth';
import { isBrowser } from '../../../utils/browser';
import { EMAIL_VALIDATION } from '../../../utils/regexes';
import './memberInviteModal.scss';
import CustomButton, {
  bsButtonTypes,
  buttonOptions,
} from '../../global/customButton/customButton';
import Loading from '../../global/loading/loading';

type InviteForm = {
  [key: string]: {
    value: string;
    isValid: boolean;
    isTouched: boolean;
    validator: (value: string) => boolean;
  };
};

const MemberInviteModal: React.FC<{
  setModal: Dispatch<SetStateAction<boolean | null>>;
  locale: string;
  setAlert: Dispatch<SetStateAction<boolean | null>>;
  updateList: (newMembersList: MembersListData) => void;
}> = ({ setModal, locale, setAlert, updateList }) => {
  const { userSelectedOption, i18n } = useContext(GlobalContext);
  const defaultMessage = `${i18n?.t(
    'dashboard.members.modal.default_message_1'
  )} ${userSelectedOption?.name} ${i18n?.t(
    'dashboard.members.modal.default_message_2'
  )}`;

  useEffect(() => {
    setForm(defaultFormState);
  }, [userSelectedOption]);

  const defaultFormState: InviteForm = {
    email: {
      value: '',
      isValid: false,
      isTouched: false,
      validator: (value: string) => EMAIL_VALIDATION.test(value),
    },
    message: {
      value: defaultMessage ?? ' ',
      isValid: false,
      isTouched: false,
      validator: (value: string) => value.length > 0,
    },
  };

  const [form, setForm] = useState<InviteForm>(defaultFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleChange = (value: string, field: string) => {
    setForm((prevVal: InviteForm) => {
      return {
        ...prevVal,
        [field]: {
          ...prevVal[field],
          value: value,
          isValid: form[field].validator(value),
        },
      };
    });
  };

  const handleBlur = () => {
    setForm((prevVal: InviteForm) => {
      return {
        ...prevVal,
        email: { ...prevVal.email, isTouched: true },
      };
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    if (!form.email.isValid)
      return setForm((prevVal: InviteForm) => {
        return {
          ...prevVal,
          email: { ...prevVal.email, isTouched: true },
        };
      });

    setSubmitting(true);
    const formattedMessage = form.message.value.trim();
    const jwt = isBrowser ? window.localStorage.getItem('jwt') : null;
    const data = {
      ...(userSelectedOption?.type === 'business' && {
        business: userSelectedOption.id,
      }),
      ...(userSelectedOption?.type === 'opportunity-provider' && {
        opportunityProvider: userSelectedOption.id,
      }),
      email: form.email.value,
      message: formattedMessage !== '' ? formattedMessage : defaultMessage,
      locale,
    };

    axios
      .post(
        `${process.env.GATSBY_STRAPI_URL}/api/members/invite`,
        {
          data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setAlert(true);
          setModal(false);
          setSubmitting(false);
          setForm(defaultFormState);
          updateList(response.data.members);
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <div className="invite-modal-wrapper">
      <h2>{i18n?.t('dashboard.members.modal.title')}</h2>
      <form onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)}>
        <label>{i18n?.t('dashboard.members.modal.fields.email')}</label>
        <input
          type="email"
          onChange={(e) => handleChange(e.target.value, 'email')}
          onBlur={handleBlur}
          className={
            !form.email.isValid && form.email.isTouched ? 'red-border' : ''
          }
          value={form.email.value}
        />
        {!form.email.isValid && form.email.isTouched && (
          <span className="warning">
            {i18n?.t('dashboard.members.modal.fields.email.error')}
          </span>
        )}
        <label className="msg-label">Message</label>
        <textarea
          onChange={(e) => handleChange(e.target.value, 'message')}
          value={form.message.value}
        />

        <div className="buttons">
          <CustomButton
            disabled={!form.email.isValid}
            style={buttonOptions.filled}
            type={bsButtonTypes.submit}
            classSuffix="--fullwidth dashboard-disabled"
          >
            {!submitting && i18n?.t('dashboard.members.modal.send')}
            {submitting && <Loading type="btn" />}
          </CustomButton>
          <CustomButton
            style={buttonOptions.filled_light}
            type={bsButtonTypes.button}
            classSuffix="--fullwidth"
            onClick={() => setModal(false)}
          >
            {i18n?.t('dashboard.members.modal.cancel')}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default MemberInviteModal;
